<template>
	<div class="dashboard">
    <div class="dashboard__container pt-5 pb-5">
      <div class="dashboard__container--header pa-0 flex-column align-start" style="align-items:flex-start;">
        <h1 class="mb-2">My Insurance</h1>
        <p>Workers are covered by Occupational Accident Insurance on 1099 shifts (i.e., when providing services as an independent contractor of Jump Staffing) or Workers' Compensation Insurance on W2 shifts (i.e., when providing services as an employee of Jump Employee Services, LLC). </p>
      </div>
      
      <div class="dashboard__container--body pt-3">
        <div class="dashboard__container--body--col flex" v-if="userProfile.workerId">
          <Card status="blank" url="/insurance/oa" :title="`My Occupational Accident Insurance`" :description="`Your insurance carrier is Great American Insurance Group. To report a claim, call 833-444-0161. Your policy number is #OA4504382.`" />
        </div>
        <div class="dashboard__container--body--col flex" v-if="userProfile.employeeId && userProfile.employeeOnboardingComplete">
          <Card status="blank" url="/insurance/wc" :title="`My Workers' Compensation Insurance`" :description="`View your carrier information, policy numbers, and access forms.`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Card from '@/components/Modules/Card.vue'

export default {
  name: 'insurance',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    hasID() {
      if (this.userProfile && this.userProfile.id_card_path) {
        return "approved"
      } else {
        return false
      }
    },
    hasResume() {
      if (this.userProfile && this.userProfile.picture) {
        return "approved"
      } else {
        return false
      }
    },
    hasCerts() {
      if (this.userProfile && this.userProfile.certs && this.userProfile.certs.length >= 1) {
        return "approved"
      } else {
        return false
      }
    },
    hasPhoto() {
      if (this.userProfile && this.userProfile.photoUrl) {
        return "approved"
      } else {
        return false
      }
    },
    hasHarassment() {
      if (this.userProfile && this.userProfile.harassmentCerts && this.userProfile.harassmentCerts.length >= 1 && this.userProfile.employeeId && (this.userProfile.address && this.userProfile.address.state && this.userProfile.address.state == 'CA')) {
        return "approved"
      } else {
        return false
      }
    }
  },
  components: {
    Loader,
    Card
  },
  
  beforeDestroy () {
  }
}
</script>